import { queryOptions } from '@tanstack/vue-query'
import type { Exam } from '~/domains/exam/content'
import { useApi } from '~/domains/global/composables/useApi'

export const getUserEssays = () => {
  return queryOptions({
    queryKey: ['essays'],
    queryFn: async () => {
      const api = useApi()
      const data = await api<EssayBasic[]>('/essays', {
        method: 'GET',
      })

      return data
    },
    staleTime: 1000 * 60 * 60,
  })
}

type CorrectionBasic = {
  grades: number[]
  id: string
  state: 'IN_PROGRESS' | 'FINISHED'
  createdAt: string
}

export type WriteType = 'GUIDED' | 'TRAINING'

export type EssayBasic = {
  corrections: CorrectionBasic[]
  title: string
  createdAt: string
  examType: Exam
  writeType: WriteType
  id: string
  state: 'DRAFT' | 'IN_PROGRESS' | 'FINISHED'
  themeId: string
  updatedAt: string
}
