import { queryOptions } from '@tanstack/vue-query'

export const getVersionQuery = () => {
  return queryOptions({
    queryKey: ['version'],
    queryFn: async () => {
      const res = await fetch("/api/version");
      const data: {
        version: string
      } = await res.json();

      return data
    },
    gcTime: 0,
    staleTime: 0,
  })
}